<template>
  <div class="costIndex defined_Dialog">
    <router-view></router-view>
  </div>
</template>

<script>
  export default{
    name:"costIndex",
  }
</script>

<style lang="less">
  .costIndex{
    width: 100%;
    height: 100%;
  }
</style>
<!-- 导入单据公共样式 -->
<style lang="less" src="@/less/Comm/comm_billing.less"></style>
